<template>
  <table class="table table-bordered table-xs text-dark">
    <thead>
      <tr>
        <th colspan="2">Time</th>
        <th rowspan="2">Fixed Wing</th>
        <th rowspan="2">Rotary</th>
        <th rowspan="2">Resident</th>
        <th rowspan="2">Non Resident</th>
        <th rowspan="2">Touch & Go</th>
        <th rowspan="2">Total Movements</th>
      </tr>
      <tr>
        <th>From</th>
        <th>To</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in rows" :class="index % 2 ? 'row-odd' : 'row-even'">
        <td>{{ row.from }}</td>
        <td>{{ row.to }}</td>
        <td>{{ row.fixed_wing }}</td>
        <td>{{ row.helicopter }}</td>
        <td>{{ row.resident }}</td>
        <td>{{ row.non_resident }}</td>
        <td>{{ row.touch_go }}</td>
        <td>{{ row.total }}</td>
      </tr>

      <tr class="head">
        <th colspan="2" rowspan="2">Totals for <br /> {{ date.toLocaleDateString() }}</th>
        <th>Fixed Wing</th>
        <th>Rotary</th>
        <th>Resident</th>
        <th>Non Resident</th>
        <th>Touch & Go</th>
        <th>Total Movements</th>
      </tr>
      <tr class="row-totals">
        <td>{{ totals.fixed_wing }}</td>
        <td>{{ totals.helicopter }}</td>
        <td>{{ totals.resident }}</td>
        <td>{{ totals.non_resident }}</td>
        <td>{{ totals.touch_go }}</td>
        <td>{{ totals.total }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "DailyBreakdownReport",
  props: {
    date: {
      type: Date,
      required: true
    },
    movements: {
      type: Array,
      required: true
    },
    movementTypes: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      rows: Array.from({length: 32}, (_, index) => {
        let from, to;
        if(index === 0) {
          from = '00:00';
          to = '07:00';
        } else if(index === 31) {
          from = '22:00';
          to = '00:00';
        } else {
          const fromHour = Math.floor(6.5 + (index / 2));
          const fromMin = (index % 2) ? '00' : '30';
          from = `${fromHour}:${fromMin}`.padStart(5, '0');
          const toHour = Math.floor(7 + (index / 2));
          const toMin = (index % 2) ? '30' : '00';
          to = `${toHour}:${toMin}`.padStart(5, '0');
        }
        return {
          from, to,
          fixed_wing: 0,
          helicopter: 0,
          resident: 0,
          non_resident: 0,
          touch_go: 0,
          total: 0
        }
      }),
      totals: {
        fixed_wing: 0,
        helicopter: 0,
        resident: 0,
        non_resident: 0,
        touch_go: 0,
        total: 0
      },
      isWaitingForMovementTypes: false
    }
  },
  watch: {
    movementTypes: function() {
      if(this.isWaitingForMovementTypes && this.movementTypesNotEmpty()) {
        this.isWaitingForMovementTypes = false;
        this.processMovements();
      }
    },
    movements: function() {
      if(this.movementTypesNotEmpty()) {
        this.processMovements();
      } else {
        this.isWaitingForMovementTypes = true;
      }
    }
  },
  methods: {
    resetData() {
      this.rows = Array.from({length: 32}, (_, index) => {
        let from, to;
        if(index === 0) {
          from = '00:00';
          to = '07:00';
        } else if(index === 31) {
          from = '22:00';
          to = '00:00';
        } else {
          const fromHour = Math.floor(6.5 + (index / 2));
          const fromMin = (index % 2) ? '00' : '30';
          from = `${fromHour}:${fromMin}`.padStart(5, '0');
          const toHour = Math.floor(7 + (index / 2));
          const toMin = (index % 2) ? '30' : '00';
          to = `${toHour}:${toMin}`.padStart(5, '0');
        }
        return {
          from, to,
          fixed_wing: 0,
          helicopter: 0,
          resident: 0,
          non_resident: 0,
          touch_go: 0,
          total: 0
        }
      });
      this.totals = {
        fixed_wing: 0,
        helicopter: 0,
        resident: 0,
        non_resident: 0,
        touch_go: 0,
        total: 0
      };
    },
    movementTypesNotEmpty() {
      return Object.keys(this.movementTypes).length > 0;
    },
    processMovements() {
      const touchGoId = Object.keys(this.movementTypes).find(id => this.movementTypes[id] === 'touch-go');

      this.resetData();

      for(const movement of this.movements) {
        this.totals.total++;

        const date = new Date(movement.started_at.replace(' ','T')+'Z');
        const hour = date.getHours();
        const min = date.getMinutes();
        let index;
        if(hour < 7) {
          index = 0;
        } else if(hour > 21) {
          index = 31;
        } else {
          index = (hour - 6.5) * 2 + (min > 30 ? 1 : 0);
        }

        this.rows[index].total++;

        if(movement.aircraft_type === 'fixed_wing') {
          this.rows[index].fixed_wing++;
          this.totals.fixed_wing++;
        }
        if(movement.aircraft_type === 'helicopter') {
          this.rows[index].helicopter++;
          this.totals.helicopter++;
        }
        if(movement.resident_id !== null) {
          this.rows[index].resident++;
          this.totals.resident++;
        } else {
          this.rows[index].non_resident++;
          this.totals.non_resident++;
        }

        if(movement.event_type_id === touchGoId) {
          this.rows[index].touch_go++;
          this.totals.touch_go++;
        }
      }
    }
  }
}
</script>

<style scoped>
thead, tr.head {
  background-color: #ffca38;
}

tr.row-odd {
  background-color: #eaff4f;
}

tr.row-even {
  background-color: #d1ff80;
}

tr.row-totals {
  background-color: #aeffe8;
}

thead th, th, td {
  border-color: #333;
  border-width: 1px;
}

</style>