<template>
  <div class="col mr-auto total">
    <div class="totals-chart" ref="chart"></div>
    <div>
      <p class="card-text">{{ title }}</p>
      <h3>{{ total }}</h3>
    </div>
  </div>
</template>

<script>
import ApexCharts from "../../public/app-assets/vendors/js/charts/apexcharts/apexcharts.min";

export default {
  name: "TotalChart",
  props: {
    title: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    series: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartOptions: {
        chart:       {
          type: 'donut'
        },
        plotOptions: {
          pie: {
            size:    50,
            offsetY: 33,
            offsetX: -12,
            donut:   {
              labels: {
                show:  true,
                name:  {show: false},
                value: {
                  offsetY:   0,
                  formatter: (val, opts) => {
                    const total = opts.globals.series.reduce((a, b) => a + b);
                    if (total === 0) return '0%';
                    return (100 * (val / total)).toFixed() + '%';
                  }
                },
                total: {
                  show:      true,
                  color:     '#6b6f82',
                  formatter: (opts) => {
                    let total = 0;
                    for (let i = 0; i < 3; i++) {
                      total += opts.globals.seriesTotals[i];
                    }
                    if (total === 0) return '0%';
                    return (100 * total / (total + opts.globals.seriesTotals[3])).toFixed() + '%';
                  }
                }
              }
            }
          }
        },
        labels:      ['Take off', 'Landing', 'Touch & go', 'Remaining'],
        colors:      ['#ddd', '#ddd', '#ddd', '#fafafa'],
        dataLabels:  {
          enabled: false
        },
        legend:      {
          show: false
        }
      }
    }
  },
  mounted: function () {
    const chart = new ApexCharts(
        this.$refs.chart,
        {
          ...this.chartOptions,
          ...{
            series: this.series
          }
        }
    );
    chart.render();
  }
}
</script>

<style scoped>
@import '../../public/app-assets/vendors/css/charts/apexcharts.css';

.total {
  box-shadow: 3px 3px 3px #eee;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
}

.total .card-text {
  white-space: nowrap;
  margin-bottom: 0;
}

.total .totals-chart {
  width: 120px;
  height: 120px;
}

</style>
