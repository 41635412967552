<template>
  <div class="card totals">
    <div class="card-header">
      <h2 class="card-title text-uppercase">Movements</h2>
    </div>
    <div class="card-content" aria-expanded="true">
      <div class="card-body">
        <div class="container">
          <div class="row mt-1">
            <h4>Summary</h4>
          </div>
          <div class="row">
            <TotalChart v-if="totals.today.total !== 0" title="Today" :total="totals.today.total" :series="totals.today.series" />
            <TotalChart v-if="totals.week.total !== 0" title="This Week" :total="totals.week.total" :series="totals.week.series" />
            <TotalChart v-if="totals.month.total !== 0" title="This Month" :total="totals.month.total" :series="totals.month.series" />
            <TotalChart v-if="totals.year.total !== 0" title="This Year" :total="totals.year.total" :series="totals.year.series" />
          </div>
          <div v-if="movementsDate" class="row mt-4">
            <div class="col-xs-6 text-right">
              <h4 style="line-height: 2.1">Bagby Airfield movements for:</h4>
            </div>
            <div class="col-xs-3 text-left">
              <select class="form-control" v-model="selectedDate" @change="setMovementsDate(selectedDate);refreshMovements()">
                <option v-for="date in dates" :value="date">{{ date.toLocaleDateString() }}</option>
              </select>
            </div>
          </div>
          <div v-if="movementsDate" class="row mt-4">
            <DailyBreakdownReport :date="movementsDate" :movements="movements" :movementTypes="movementTypes" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import TotalChart from "@/components/TotalChart";
import DailyBreakdownReport from "@/components/DailyBreakdownReport";

export default {
  name: "Home",
  components: {DailyBreakdownReport, TotalChart},
  data() {
    return {
      error: "",
      movementTypes: {},
      movementThresholds: {},
      totals: {
        today: {
          chart:  null,
          total: 0,
          series: [0,0,0,0]
        },
        week: {
          chart:  null,
          total: 0,
          series: [0,0,0,0]
        },
        month: {
          chart:  null,
          total: 0,
          series: [0,0,0,0]
        },
        year: {
          chart:  null,
          total: 0,
          series: [0,0,0,0]
        },
      },
      movements: [],
      movementsDate: null,
      dates: [
        new Date(Date.now() - 86400000),
        new Date(Date.now() - 172800000),
        new Date(Date.now() - 259200000),
        new Date(Date.now() - 345600000),
        new Date(Date.now() - 432000000),
        new Date(Date.now() - 518400000),
        new Date(Date.now() - 604800000),
      ],
      selectedDate: null,
      movementsFilter: {fields: {}}
    }
  },
  methods: {
    setMovementsDate: function(date) {
      const min = new Date(date.getTime());
      min.setHours(0,0,0,0);

      const max = new Date(date.getTime());
      max.setHours(23,59,59,999);

      this.movementsFilter.fields.started_at = {
        min: min.toISOString(),
        max: max.toISOString()
      }

      this.movementsDate = min;
    },
    getMovementTypes: async function() {
      const movementTypes = await APIService.getMovementTypes();
      this.movementTypes = movementTypes.reduce((result, item) => {
        result[item.event_type_id] = item.event_name;
        return result;
      }, {});
    },
    getMovementThresholds: async function() {
      const thresholds = await APIService.getMovementThresholds();
      this.movementThresholds = thresholds.reduce((result, item) => {
        result[item.slug] = item.value;
        return result;
      }, {});
    },
    refreshMovements: async function() {
      this.movements = await APIService.getMovements(this.movementsFilter);
    },
    refreshTotals: async function () {
      try {
        const periods = [
          {name: 'today', threshold: this.movementThresholds.all_aircraft_daily },
          {name: 'week', threshold: this.movementThresholds.all_aircraft_weekly },
          {name: 'month', threshold: this.movementThresholds.all_aircraft_monthly },
          {name: 'year', threshold: this.movementThresholds.all_aircraft_yearly }
        ];
        const totals = await APIService.getTodaysMovementTotals();
        totals.forEach(total => {
          let index;
          switch(this.movementTypes[total.event_type_id]) {
            case 'take-off': index = 0; break;
            case 'landing': index = 1; break;
            case 'touch-go': index = 2; break;
            default: return;
          }

          periods.forEach(period => {
            this.totals[period.name].series[index] += total[period.name];
            this.totals[period.name].total += total[period.name];
          });

        });

        periods.forEach(period => {
          const total = this.totals[period.name];
          const remaining = period.threshold - total.total
          total.series[3] = remaining > 0 ? remaining : 0;
        });

      } catch (e) {
        this.error = e.message;
      }
    }
  },
  async created() {
    await Promise.all([
      this.getMovementThresholds(),
      this.getMovementTypes()
    ]);
    await this.refreshTotals();
    this.selectedDate = this.dates[0];
    this.setMovementsDate(this.selectedDate);
    await this.refreshMovements();
  }
}

</script>

<style scoped>
.totals .row {
  gap: 10px;
}
</style>
